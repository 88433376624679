import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6786bafc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading-panel" }
const _hoisted_2 = { class: "p-as-center p-m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProgressSpinner, {
          style: {"width":"60px","height":"60px"},
          strokeWidth: "5",
          fill: "#ffffff",
          animationDuration: ".8s"
        })
      ])
    ])
  ]))
}
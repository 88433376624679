
import { defineComponent, computed, watch } from "vue";
import liff from "@line/liff";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { GlobalActionTypes } from "@/store/modules/global/action-types";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const getAppData = () => {
      store.dispatch(GlobalActionTypes.GET_APP_DATA, true);
    };

    const initLineLiff = () => {
      const queryString = decodeURIComponent(window.location.search);
      const params = new URLSearchParams(queryString);
      const searchParam = params.get("liff.state");

      liff
        .init({
          liffId: computed(() => store.getters.getBrand.lineLiffId).value,
        })
        .then(() => {
          if (searchParam) {
            router.push(searchParam);
          }
        })
        .catch((error) => {
          alert("error init line liff " + error);
          console.error("error init line liff", error);
        });
    };

    watch(
      computed(() => store.getters.getBrand.lineLiffId),
      (currentValue) => {
        if (currentValue) {
          initLineLiff();
        }
      }
    );

    return { getAppData };
  },
  async beforeCreate() {
    this.getAppData();
  },
});
